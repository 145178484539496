<template>

  <section class="mt-16 mx-auto container py-12 lg:px-4 xl:px-0 mx-auto container ">
    <div class="flex flex-col sm:flex-col md:flex-row lg:flex-row">
      <div class="flex flex-col lg:w-6/12 md:w-6/12 px-4 lg:px-0 justify-center items-start">
        <p class="text-2xl text-gray-800 font-light">Hi! We're <span class="custom-foreground-color">Sample Games</span></p>
        <h1 class="mt-5 text-5xl sm:text-6xl md:text-4xl lg:text-6xl font-extrabold leading-tight text-gray-800">We make a variety of experimental games.</h1>

        <a class="Sf-ui-pro mt-10 flex items-center text-xl custom-foreground-color font-regular custom-border-bottom pb-2 mr-3 focus:outline-none" href="mailto:samplegames.cs@gmail.com">
          Connect with us
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="5" y1="12" x2="19" y2="12" />
            <line x1="15" y1="16" x2="19" y2="12" />
            <line x1="15" y1="8" x2="19" y2="12" />
          </svg>
        </a>
      </div>
      <div class="xl-w-11/12 sm:w-1/2 mt-8 sm:mt-0">
        <div class="flex items-center justify-center sm:justify-end w-full">
          <img loading="lazy" class="w-3/5" src="../assets/images/hero.png" alt="header image" />
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Main',
  setup() {
    return {
    }
  }
})
</script>
